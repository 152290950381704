/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * This checks for the presence of wsit (web session idle timeout) cookie, since AAD does not emit this claim in Access Token we have to set a cookie
 * as a workaround until AAD starts emitting as Shared ABT feature depends on presence of this claim to function properly.
 *	> 'owa-config/lib/universalCookies' import is restricted from being used. The long term plan is to deprecate the cookie so we should only be using this sparingly */
import { getCookie } from 'owa-config/lib/universalCookies';

// Web Session Idle Timeout Cookie key
export const WSIT_COOKIE_KEY = 'wsit';

export function getIdleSessionTimeout(): string | undefined {
    const wsit_value = getCookie(WSIT_COOKIE_KEY);
    return wsit_value;
}
